body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (orientation: landscape) {
  body {
    max-width: 700px;
  }
  }

.bazaar-root_theme_dark {
  --g-color-base-brand: #9496b5;
  --g-color-base-brand-hover: #878aad;
  --g-color-base-selection: #333773;
  --g-color-base-selection-hover: #3f437b;
  --g-color-line-brand: #9496b5;
  --g-color-text-brand: #aeb0c7;
  --g-color-text-link: #aeb0c7;
  --g-color-text-link-hover: #c8c9d9;
}
.bazaar-root_theme_light {
  --g-color-base-brand: #619fc5;
  --g-color-base-brand-hover: #4e93be;
  --g-color-base-selection: #ebf3f8;
  --g-color-base-selection-hover: #d7e7f0;
  --g-color-line-brand: #619fc5;
  --g-color-text-brand: #367ba5;
  --g-color-text-link: #367ba5;
  --g-color-text-link-hover: #35637e;
}
.bazaar-root_font {
  font-size: var(--g-text-body-2-font-size);
  --g-text-body-short-font-size: var(--g-text-body-2-font-size);
}
.bazaar-root_background {
  background: var(--g-color-base-background);
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.squares {
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
}

.squares:focus {
  outline:  6px solid #666;
}

.kbd-navigation .squares:focus {
  background: #ddd;
}

 .modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation-name: appear;
  animation-duration: 300ms;
}

.modal-dialog {
  width: 100%;
  max-width: 550px;
  color: rgb(250, 220, 220);
  background: white;
  /* background: rgba(85, 85, 85, 0.8); */
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
}

/* .modal-header, */
.modal-footer {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.modal-header {
  border-bottom: 1px solid #dbdbdb;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #dbdbdb;
  justify-content: flex-end;
}

.modal-close {
  cursor: pointer;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-body {
  overflow: auto;
}

.modal-content {
  padding: 1rem;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}